import store from "../../store";

const setActiveOrg = (org) => {
    let dataConsumed = [
        fetch(`/api/org/${org.id}`)
            .then((r) => r.json())
            .then((result) => {
                store.state.active_org = org;
                store.state.payment = result.payment.brand || "Not on file";
                store.state.plan = result.plan;
                store.state.target_plan = result.plan;
                store.state.usage = result.usage;
                store.state.days_left_in_cycle = parseInt((new Date(result.cycle_end) - new Date()) / 1000 / 24 / 60 / 60);
                store.state.keys = result.tokens;
                store.state.referral_code = result.referral_code;
                store.state.keys_item = [];
                for (let key in store.state.keys) {
                    store.state.keys_item.push({ key: key, name: store.state.keys[key].name, active: store.state.keys[key].active });
                }
            }),
        fetch(`/api/org/${org.id}/billing`)
            .then((r) => r.json())
            .then((bill) => {
                store.state.metered_cost = bill.cost / 100;
                store.state.next_tier = bill.next_tier;
                store.state.credits = bill.available_credits;
                if (bill.rate !== 0) {
                    store.state.rate = `${bill.rate / 100} / 100K requests`;
                } else {
                    store.state.rate = "Free";
                }
            }),
        fetch(`/api/org/${org.id}/subs`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let all_subscriptions = {};
                for (let sub of data.subscribed) {
                    all_subscriptions[sub.id] = sub;
                }
                for (let sub of data.unsubscribed) {
                    all_subscriptions[sub.id] = sub;
                }
                store.state.subscribed = data.subscribed;
                store.state.unsubscribed = data.unsubscribed;
                store.state.all_subscriptions = all_subscriptions;
            }),
    ];

    Promise.all(dataConsumed).then(() => {
        if (typeof window !== "undefined" && window) {
            store.state.hasDisplayed = !!window.localStorage.getItem("confirmedCryptoCredits");
        }
        if (
            !store.state.hasDisplayed &&
            ((store.state.plan == "CRYPTO" && store.state.credits < 100000) ||
                (store.state.plan == "BUIDL" && store.state.credits + 3000000 - store.state.usage < 100000))
        ) {
            store.state.directToCredits = true;
        }
    });
};

export default setActiveOrg;
