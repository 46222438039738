<template>
    <div class="loader">
        <div class="img">
            <img src="https://res.cloudinary.com/ferventdev/image/upload/v1655149119/rivet.cloud/1487_lulkxs.gif" alt="" />
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.loader {
    @include scaffold;
    z-index: 300;
    .img {
        width: 5em;
        position: fixed;
        background: white;
        z-index: 301;
        top: 50%;
        border-radius: 4px;
        left: 48%;
        padding: 1rem;
        img {
            width: 90%;
            margin: 0 auto;
        }
        @include media("<=tablet") {
            width: 4.5em;
        }
    }
}
</style>
